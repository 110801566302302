<template>
    <div>
        <div class="filters">
            <v-toolbar flat>
                <v-toolbar-title>{{ $t('src\\views\\waitingLists\\list.filter_title') }}</v-toolbar-title>
            </v-toolbar>
            <v-row class="mx-0 mb-4">
                <v-col sm="3" class="py-0">
                    <v-text-field
                        v-model="filter.blogger"
                        :label="$t('src\\views\\waitingLists\\list.blogger')"
                        hide-details
                    />
                </v-col>
                <v-col sm="3" class="py-0">
                    <v-text-field
                        v-model="filter.name"
                        :label="$t('src\\views\\waitingLists\\list.name')"
                        hide-details
                    />
                </v-col>
                <v-col sm="3" class="py-0">
                    <v-text-field
                        v-model="filter.phone"
                        :label="$t('src\\views\\waitingLists\\list.phone')"
                        hide-details
                    />
                </v-col>
                <v-col sm="3" class="py-0">
                    <v-text-field
                        v-model="filter.email"
                        :label="$t('src\\views\\waitingLists\\list.email')"
                        hide-details
                    />
                </v-col>
                <v-col sm="12" class="d-flex justify-end">
                    <v-btn
                        color="primary"
                        @click="filterApply">{{ $t('src\\views\\waitingLists\\list.filter_apply') }}</v-btn>
                    &nbsp;&nbsp;
                    <v-btn
                        color=""
                        @click="filterReset">{{ $t('src\\views\\waitingLists\\list.filter_reset') }}</v-btn>
                </v-col>
            </v-row>
        </div>
        <v-data-table
            :headers="headers"
            :items="entities"
            :server-items-length="entitiesCount"
            :items-per-page="itemsPerPage"
            class="waitingLists-list"
            :loading="loading"
            :footer-props="{ 'itemsPerPageOptions': [50, 100, 200, -1] }"
            @pagination="paginate"
        >
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title>{{ $t('src\\views\\waitingLists\\list.494') }}</v-toolbar-title>
                    <v-spacer />
                    <v-btn
                        color="primary"
                        @click="exportToCsv">{{ $t('src\\views\\waitingLists\\list.export') }}</v-btn>
                </v-toolbar>
            </template>
            <template v-slot:[`item.createdAt`]="{ item }">
                {{ item.createdAt | moment('DD.MM.YYYY HH:mm') }}
            </template>
            <template v-slot:[`item.blogger`]="{ item }">
                {{ item.blogger ? item.blogger.social : '' }} ({{ item.blogger ? item.blogger.phone : '' }})
            </template>
        </v-data-table>
    </div>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import { ACCESS } from '@/vars';

    export default {
        name: 'WaitingList',
        components: {
        },
        data: () => ({
            ACCESS,
            headers: [],
            page: 1,
            itemsPerPage: 50,
            loading: true,
            filter: {},
            filterApplied: {}
        }),
        computed: {
            ...mapState('auth', ['user']),
            ...mapState('waitingLists', ['entities', 'entitiesCount']),
        },
        async mounted() {
            await store.dispatch('auth/fetch');
            this.headers = [
                { text: this.$t('src\\views\\waitingLists\\list.createdAt'), value: 'createdAt', sortable: false },
                { text: this.$t('src\\views\\waitingLists\\list.blogger'), value: 'blogger', sortable: false },
                { text: this.$t('src\\views\\waitingLists\\list.name'), value: 'name', sortable: false },
                { text: this.$t('src\\views\\waitingLists\\list.phone'), value: 'phone', sortable: false },
                { text: this.$t('src\\views\\waitingLists\\list.email'), value: 'email', sortable: false },
            ]
        },
        methods: {
            async getQuizes() {
                this.loading = true;
                await store.dispatch('waitingLists/fetch', { filter: this.filterApplied, page: this.page, itemsPerPage: this.itemsPerPage });
                this.loading = false;
            },
            async exportToCsv() {
                this.loading = true;
                const data = await store.dispatch('waitingLists/exportToCsv', { filter: this.filterApplied });
                if (data.file) {
                    window.open(`${ process.env.VUE_APP_STATIC_URL }/${ data.file }`, '_blank');
                }
                this.loading = false;
            },
            async paginate({ page, itemsPerPage }) {
                this.page = page;
                this.itemsPerPage = itemsPerPage;
                await this.getQuizes();
            },
            async filterApply() {
                this.filterApplied = this.filter;
                await this.getQuizes();
            },
            async filterReset() {
                this.filterApplied = {};
                await this.getQuizes();
            }
        }
    }
</script>
